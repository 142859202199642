import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Grid, Paper } from '@mui/material';
import API from 'api';
import Loading from 'components/Loading';
import SpaceSummary from './SpaceSummary';
import PlaceSummary from './PlaceSummary';
import TraderSummary from './TraderSummary';
import NoviceModal from './NoviceModal';
import { useSpace } from 'contexts/space_v2.context';
import useSpaceAuthinfo from 'hooks/useSpaceAuthinfo';
import { AuthContext } from 'contexts/auth.context';


const Item = ({ children }) => {
    return (
        <Paper
            elevation={0}
            variant='outlined'
            sx={{ p: 2 }}
        >{children}</Paper>
    )
}

const DashboardPage = () => {
    const space = useSpace();
    const spaceAuthinfo = useSpaceAuthinfo();

    const myAuths = useMemo(() => {
		if (spaceAuthinfo) return Object.entries(spaceAuthinfo).filter(([key, value]) => value).map(el => el[0]);
	}, [ spaceAuthinfo ]);

    const [ memberCount, setMemberCount ] = useState(null);
    const [ itemCount, setItemCount ] = useState(null);
    const [ places, setPlaces ] = useState();
    const [ traders, setTraders ] = useState();
    useEffect(() => {
        if (space) {
            const params = { spaceId: space.id };
            API.get('/common/dashboard-page-info', { params })
            .then(data => {
                setMemberCount(data.memberCount)
                setItemCount(data.itemCount);
                setPlaces(data.places);
                setTraders(data.traders);
            })
        }
    }, [ space ]);

    if (!space || !myAuths || memberCount === null || itemCount === null || !places || !traders) return (<Loading />);

	return (
		<Grid container spacing={2}>
            <Grid item xs={12} md={12}>
                <Item>
                    <SpaceSummary
                        space={space}
                        memberCount={memberCount}
                        itemCount={itemCount}
                        myAuths={myAuths}
                    />
                </Item>
            </Grid>
            
            {places.map(place => <Grid item key={place.id} xs={12} md={6}>
                <Item>
                    <PlaceSummary place={place} />
                </Item>
            </Grid>)}

            {traders.map(trader => <Grid item key={trader.id} xs={12} md={6}>
                <Item>
                    <TraderSummary trader={trader} />
                </Item>
            </Grid>)}
            
            {/* <NoviceModal /> */}
        </Grid>
	);
}

export default DashboardPage;