import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Box, Card, CardActionArea, CardActions, CardContent, Container, Grid, Typography, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { AuthContext } from 'contexts/auth.context';
import API from 'api';
import colors from 'constants/colors';
import Loading from 'components/Loading';
import NeedLoginDialog from 'components/NeedLoginModal';
import SpaceFormModal from './SpaceFormModal';
import { useNavigate } from 'react-router-dom';
import MemberRequestModal from './MemberRequestModal';
import { AppContext } from 'contexts/app.context';


const MemberRequestCard = ({ request, reload }) => {
    const { showSnackbar } = useContext(AppContext);

    const viewdata = useMemo(() => {
        if (request.state === 'requested') {
            return ({
                subtitle: '참여 승인 대기중..',
                color: 'text.secondary',
            });
        }
        else if (request.state === 'replied' && request.isAccepted) {
            return ({
                subtitle: '참여 요청이 허가되었습니다.',
                color: 'success.main',
            })
        }
        else if (request.state === 'replied' && !request.isAccepted) {
            return ({
                subtitle: '참여 요청이 거절되었습니다.',
                color: 'error.main',
            })
        }
    }, [ request ]);

    const handleCancel = () => {
        API.delete('/request/cancel-request/' + request.id)
        .then(() => { 
            showSnackbar('취소했습니다.');
            reload();
        });
    }

    const handleConfirm = () => {
        const body = { requestId: request.id };
        API.put('/request/confirm-request', body)
        .then(() => {
            showSnackbar('확인했습니다.');
            reload();
        });
    }

    return (
        <Card>
            <CardContent sx={{ position: 'relative' }}>
                <Typography variant='body1' sx={{ fontSize: 14, mb: 1 }} color="text.primary" gutterBottom>{request.space.name}</Typography>
                <Typography variant='body2' color={viewdata.color}>{viewdata.subtitle}</Typography>
                {/* <EditIcon sx={{ position: 'absolute', right: '12px', top: '12px', cursor: 'pointer' }} fontSize="small" color="action" onClick={handleModifySpaceClick(space)} /> */}
                {/* <Typography variant="body2" sx={{}} component="div">회원: n명</Typography> */}
            </CardContent>
            <CardActions>
                {request.state === 'requested' && <Button  size="small" variant="contained" color="warning" onClick={handleCancel}>취소</Button>}
                {(request.state === 'replied') && <Button size="small" variant="contained" color="info" onClick={handleConfirm}>확인</Button>}
            </CardActions>
        </Card>
    );
}

const SpaceInvitationCard = ({ request, reload }) => {
    const { showSnackbar } = useContext(AppContext);

	const handleAccept = () => {
		const body = { 
			requestId: request.id,
			reply: 'Y'
		};
		API.put('/request/reply-request', body)
		.then(() => {
			showSnackbar('수락했습니다.');
			reload();
		});
	}

	const handleReject = () => {
		const body = { 
			requestId: request.id,
			reply: 'N'
		};
		API.put('/request/reply-request', body)
		.then(() => {
			showSnackbar('거절했습니다.');
			reload();
		});
	}

    return (
        <Card>
            <CardContent sx={{ position: 'relative' }}>
                <Typography variant='body1' sx={{ fontSize: 14, mb: 2 }} color="text.primary" gutterBottom>{request.space.name}</Typography>
                <Typography variant='body2' color="text.secondary">초대에 응하시겠습니까?</Typography>
            </CardContent>
            <CardActions>
                <Button size="small" variant="contained" color="warning" onClick={handleReject}>거절</Button>
                <Button size="small" variant="contained" color="info" onClick={handleAccept}>수락</Button>
            </CardActions>
        </Card>
    );
}

const MySpaces = () => {
    const { me, logout } = useContext(AuthContext);

    const navigate = useNavigate();

    // member request
    const [ requests, setRequests ] = useState();
    const [ requestTrigger, setRequestTrigger ] = useState();
    useEffect(() => {
        API.get('/request/my-space-requests')
        .then(data => {
            const myRequests = data.myRequests.filter(el => el.code === 'spacemember_request' && el.state !== 'accepted');
            const receivedRequests = data.receivedRequests.filter(el => el.code === 'space_invitation');
            setRequests([
                ...myRequests,
                ...receivedRequests,
            ]);
        })
    }, [ requestTrigger ]);
    const [ memberRequestModalOpen, setMemberRequestModalOpen ] = useState(false);
    const handleCloseMemberRequestModal = () => {
        setMemberRequestModalOpen(false);
        setRequestTrigger(new Date().getTime());
    }
    // end: member request

    // my spaces
    const [ spaces, setSpaces ] = useState();
    useEffect(() => {
        if (me) getMyspaces();
    }, [ me, requestTrigger ]);

    const getMyspaces = () => {
        API.get('/space/myspaces')
        .then(setSpaces);
    }

    const handleSpaceSelect = (space) => () => {
        const data = { spaceId: space.id };
        return API.put('/space/select', data)
        .then(() => {
            navigate(`/space/dashboard`);
        })
    }
    // my spaces

    const handleAddSpaceClick = () => {
        setSelected(null);
        setFormOpen(true);
    }

    const handleModifySpaceClick = (space) => () => {
        setSelected(space);
        setFormOpen(true);
    }

    // space form
    const [ selected, setSelected ] = useState();
    const [ formOpen, setFormOpen ] = useState(false);
    const handleFormClose = () => { 
        setFormOpen(false);
    }
    // end: space form

    const handleLogout = () => {
        logout()
        .then(() => { navigate('/auth/signin');})
    }

    const haveOwnerAuth = (space) => {
        return !!me.auths.find(auth => auth.code === 'space.owner' && auth.refId === space.id);
    }

    if (!me) return (<NeedLoginDialog />)

    if (!spaces || !requests) return (<Loading />);

	return (
        <Box sx={{ pt: 16, pb: 4, backgroundColor: colors.gray100, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant='h6' sx={{ mb: 4 }}>{me.nickname} 님의 스페이스</Typography>
            
            <Container maxWidth="md" sx={{ mt: 4 }}>
                {/* <Guide style={{ marginBottom: '24px' }}>{'현재 Antpocket은 베타버전으로, 무료로 사용하실 수 있습니다.\n베타버전에서의 모든 데이터는 정식버전 이후에도 유지되며 엑셀파일로 다운로드 받으실 수 있습니다.'}</Guide> */}

                <Grid container spacing={2} >
                    {spaces.map(space => <Grid item key={space.id} xs={12} sm={6} md={4}>
                        <Card>
                            <CardContent sx={{ position: 'relative' }}>
                                <Typography variant='body1' sx={{ fontSize: 14, mb: 2 }} color="text.primary" gutterBottom>{space.name}</Typography>
                                {haveOwnerAuth(space) && <EditIcon sx={{ position: 'absolute', right: '12px', top: '12px', cursor: 'pointer' }} fontSize="small" color="action" onClick={handleModifySpaceClick(space)} />}
                                <Typography variant="body2">{`회원: ${space.memberCount}명`}</Typography>
                            </CardContent>
                            <CardActions>
                                <Button size="small" variant="contained" color="primary" onClick={handleSpaceSelect(space)}>선택</Button>
                            </CardActions>
                        </Card>
                    </Grid>)}

                    {requests.map(request => <Grid item key={request.id} xs={12} sm={6} md={4} sx={{ minHeight: '140px' }}>
                        {request.code === 'spacemember_request'
                            ? <MemberRequestCard request={request} reload={() => { setRequestTrigger(new Date().getTime()); }} />
                            : <SpaceInvitationCard request={request} reload={() => { setRequestTrigger(new Date().getTime()); }} />
                        }
                    </Grid>)}

                    <Grid item xs={12} sm={6} md={4} sx={{ minHeight: '140px' }}>
                        <Card sx={{ cursor: 'pointer', height: '100%' }} onClick={() => { setMemberRequestModalOpen(true); }}>
                            <CardActionArea sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                <ContactMailIcon />
                                <Typography variant='body1' sx={{ fontSize: 14, mt: 1 }} color="text.secondary" gutterBottom>스페이스 참여 요청</Typography>
                            </CardActionArea>
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} sx={{ minHeight: '140px' }}>
                        <Card sx={{ cursor: 'pointer', height: '100%' }} onClick={handleAddSpaceClick}>
                            <CardActionArea sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                <AddIcon />
                                <Typography variant='body1' sx={{ fontSize: 14, mt: 1 }} color="text.secondary" gutterBottom>스페이스 추가</Typography>
                            </CardActionArea>
                        </Card>
                    </Grid>
                </Grid>

                <Box sx={{ display: { xs: 'block', md: 'none' }, mt: 5 }}><Button size='large' variant="contained" color="secondary" onClick={handleLogout}>로그아웃</Button></Box>
                
                {/* <Box mt={4}>
                    <MuiLink target="_blank" href={'https://discord.gg/Ed776Xas'}>{`앤트포켓 커뮤니티 at Discord →`}</MuiLink>
                </Box> */}
            </Container>

            <Box sx={{ display: { xs: 'none', md: 'block' }, position: 'absolute', right: '24px', bottom: '24px' }}><Button size='large' variant="contained" color="secondary" onClick={handleLogout}>로그아웃</Button></Box>

            <SpaceFormModal open={formOpen} handleClose={handleFormClose} space={selected} getMyspaces={getMyspaces} />

            <MemberRequestModal open={memberRequestModalOpen} handleClose={handleCloseMemberRequestModal} />
        </Box>
	);
}

export default MySpaces;