import React, { useState, useEffect } from 'react';
import { Stack } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { format } from 'date-fns';
import CircularButton from 'components/CircularButton';
import PeriodInput from 'components/PeriodInput';
import { useSpace } from 'contexts/space_v2.context';
import getFromToGroupby from 'views/statistics/getFromToGroupby';


const Filter = ({ searchDisabled }) => {
    const space = useSpace();

    const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
    const [keyword, setKeyword] = useState('');
    const [storeOrRelease, setStoreOrRelease] = useState('store');
    const [place, setPlace] = useState(null);

    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (!space) return;

        const keyword = searchParams.get('keyword') || '';
        const storeOrRelease = searchParams.get('storeOrRelease') || 'store';
        const placeId = searchParams.get('placeId');
        const startDate = searchParams.get('startDate');
		const endDate = searchParams.get('endDate');

        const { from_a, to_a, fromDate, toDate } = getFromToGroupby('day', startDate, endDate);

        setKeyword(keyword);
        setStoreOrRelease(storeOrRelease);
        setStartDate(fromDate);
        setEndDate(toDate);
        const place_a = space.places.find(el => el.id == placeId);
        setPlace(place_a);
    }, [ searchParams, space ]);

    const handleSearch = (event) => {
        event.preventDefault();

        setSearchParams({
            keyword,
            storeOrRelease,
            startDate: startDate ? format(startDate, 'yyyy-MM-dd') : '',
            endDate: endDate ? format(endDate, 'yyyy-MM-dd') : '',
            placeId: place?.id || '',
            trigger: new Date().getTime(),
        })
    }

	return (
		<form onSubmit={handleSearch}>
            <Stack spacing={1}>
                <PeriodInput
                    initialStartDate={startDate}
                    initialEndDate={endDate}
                    onPeriodChanged={(start, end) => {
                        setStartDate(start);
                        setEndDate(end);
                    }}
                    options={{
                        baseDateText: '수행일시 기준'
                    }}
                />

                <div className="mt-2 input-group input-group-sm" style={{ maxWidth: '640px' }}>
                    {/* 매입/매출 */}
                    <select className="form-select" value={storeOrRelease} onChange={event => setStoreOrRelease(event.target.value)}>
                        <option value="store">매입</option>
                        <option value="release">매출</option>
                    </select>

                    {/* 창고 */}
                    <span className="input-group-text ms-1">창고</span>
                    <select className="form-select" value={place?.id || ''} onChange={event => setPlace(space.places.find(el => el.id == event.target.value))}>
                        <option value=""></option>
                        {space.places.map(place => <option key={place.id} value={place.id}>{place.name}</option>)}
                    </select>

                    {/* 키워드 */}
                    <span className="input-group-text ms-1">키워드</span>
                    <input className='form-control' value={keyword} onChange={event => setKeyword(event.target.value)} />
                </div>

                <CircularButton type="submit" variant='contained' loading={searchDisabled}>검색</CircularButton>
            </Stack>
		</form>
	);
}

export default Filter;