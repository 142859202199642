import React, { useContext, useEffect, useState } from 'react';
import { Box, Typography, TextField, Card, CardContent, MenuItem, useMediaQuery, Button } from '@mui/material';
import API from 'api';
import { AppContext } from 'contexts/app.context';
import colors from 'constants/colors';
import { Formik } from 'formik';
import * as yup from 'yup';
import Loading from 'components/Loading';
import { Link, useNavigate } from 'react-router-dom';
import Title from 'components/Title';


const TicketWritePage = () => {
    const isHandset = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const navigate = useNavigate();

    const { showSnackbar } = useContext(AppContext);

    const [ categories, setCategories ] = useState();
    useEffect(() => {
        API.get('/common/inquiry-categories')
        .then(setCategories);
    }, []);

    if (!categories) return (<Loading />);

	return (
        <Box sx={{ flex: 1, bgcolor: colors.pageGray, padding: isHandset ? '24px 0' : '40px 0', display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
            <Box width={{ xs: '400px', sm: '500px', md: '600px' }} maxWidth={'100%'} display="flex" paddingX={2}>
                <Card style={{ flex: 1 }}>
                    <CardContent>
                        <Title>1:1 문의</Title>

                        <Formik
                            initialValues={{
                                category: '',
                                content: '',
                                files: [], 
                            }}
                            validationSchema={yup.object({
                                category: yup.string().required('필수입력입니다.'),
                                content: yup.string().required('필수입력입니다.').max(250, '250자 이내로 작성해 주세요.'),
                            })}
                            onSubmit={async (values, { setSubmitting }) => {
                                const data = new FormData();
                                data.append('category', values.category);
                                data.append('content', values.content);
                                for (let file of values.files) {
                                    data.append('files', file);
                                }
                        
                                await API.post('/ticket/ticket', data);

                                showSnackbar('문의했습니다.');
                                navigate('/?tab=inquiry', { state: { intend: 'list' } });
                            }}
                        >
                            {({ values, errors, dirty, touched, isSubmitting, isValid, handleChange, handleBlur, handleSubmit, setFieldValue, getFieldProps }) => (
                                <form onSubmit={handleSubmit}>
                                    <Box sx={{ mt: 2, py: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                                        {/* 구분 */}
                                        <TextField
                                            select
                                            variant="outlined"
                                            sx={{ alignSelf: 'stretch' }}
                                            label={'구분'}
                                            required={true}
                                            size="small"
                                            helperText={(touched.category && errors.category) || ' '}
                                            error={!!(touched.category && errors.category)}
                                            {...getFieldProps('category')}
                                        >
                                            {Object.entries(categories).map(([key, value]) => <MenuItem key={key} value={key}>{value}</MenuItem>)}
                                        </TextField>
            
                                        {/* 문의내용 */}
                                        <TextField
                                            variant="outlined"
                                            sx={{ mt: 4, alignSelf: 'stretch' }}
                                            label={'문의내용'}
                                            multiline={true}
                                            rows={8}
                                            required={true}
                                            helperText={(touched.content && errors.content) || ' '}
                                            error={!!(touched.content && errors.content)}
                                            {...getFieldProps('content')}
                                        />

                                        {/* 사진 */}
                                        <Typography variant="subtitle2" sx={{ margin: '16px 0 8px 0' }}>첨부파일</Typography>
                                        <div style={{ border: '1px solid #dddddd', padding: '4px' }}>
                                            <input type="file" multiple onChange={event => { setFieldValue('files', event.target.files); }} />
                                        </div>

                                        {/* 제출 버튼 */}
                                        <div style={{ marginTop: '24px' }}>
                                            <Button variant="contained" component={Link} type="button" to={'/?tab=inquiry'} state={{ intend: 'list' }}>목록</Button>
                                            <Button variant="contained" type="submit" color="secondary" style={{ marginLeft: '4px', color: 'white' }} disabled={!dirty || !isValid || isSubmitting}>제출</Button>
                                        </div>
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </CardContent>
                </Card>
            </Box>
        </Box>
	);
}

export default TicketWritePage;